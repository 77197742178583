import { configureStore } from "@reduxjs/toolkit";
import paymentReducer from "./paymentSlice"
import transactionReducer from "./transactionSlice"

const store = configureStore({
    reducer:{
        payment:paymentReducer,
        transaction:transactionReducer
    }
})

export default store;