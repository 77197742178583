import { createSlice } from "@reduxjs/toolkit";

const paymentPayloadSlice = createSlice({
    name:"payment",
    initialState:{
        token:null,
        instant_pay:false,
        save_card:false,
        tap_customer_id:null,
        tap_card_id:null,
        initialize_savecard:false,
        initialize_payment:false,
    },
    reducers:{
        setPaymentPayload:(state,action)=>{
          const {token,instant_pay,save_card,tap_customer_id,tap_card_id,initialize_payment,initialize_savecard} = action.payload;
          state.token = token;
          state.instant_pay = instant_pay;
          state.save_card = save_card;
          state.tap_customer_id = tap_customer_id;
          state.tap_card_id = tap_card_id;
          state.initialize_payment = initialize_payment;
          state.initialize_savecard = initialize_savecard;

        },
        resetPaymentPayload: (state) => {
            state.token = null;
            state.instant_pay = false;
            state.save_card = false;
            state.tap_card_id = null;
            state.initialize_payment = false;
            state.initialize_savecard=false;
          },
    }
})


export const { setPaymentPayload, resetPaymentPayload } = paymentPayloadSlice.actions;
export default paymentPayloadSlice.reducer;