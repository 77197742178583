import React, { useEffect, useRef, useState } from 'react'
import { redirectPageStatus, verifySaveCard } from '../services/krti.service';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentPayload } from '../redux/paymentSlice';
import { setTransactionPayload } from '../redux/transactionSlice';
import PaymentSuccess from './PaymentSuccess';

const RedirectComp = () => {


   const payload =  JSON.parse(sessionStorage.getItem("payload"))
   const cashback = sessionStorage.getItem("cashback")
   const cartValue =sessionStorage.getItem("cartValue")
   let transaction;
   try {
    transaction =   JSON.parse( sessionStorage.getItem("transaction"))
   } catch (error) {
    transaction =  sessionStorage.getItem("transaction")

   }

   const promoDetail = sessionStorage.getItem("promoDetail")
   const walletValue = sessionStorage.getItem("walletValue")
   const [widgetCompleted, setWidgetCompleted] = useState(sessionStorage.getItem("widgetCompleted"));
   const [transId, setTransId] = useState(localStorage.getItem('orderId'));

//    const setWidgetCompleted = sessionStorage.getItem("widgetCompleted")
   const useWallet = sessionStorage.getItem("useWallet")
   sessionStorage.setItem("is_tap",false)

   const [ orderStatus,setOrderStatus] = useState(false)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tapId = urlParams.get('tap_id');
    const transaction_type = urlParams.get('transaction_type');
    const transactionPayload = useSelector((state) => state.transaction)
    const dispatch = useDispatch();
    const hasMounted = useRef(false);
    useEffect(() => {
        // debugger;
        console.log('tap_id:', transaction);
        const requestChargeStatus = async () => {
            if (hasMounted.current) return true
            hasMounted.current = true
            if (transaction_type && transaction_type == "save_card") {
                const saveCard = await verifySaveCard(tapId)
                console.log("saveCard",saveCard)
                if(saveCard.data.statusCode==200){
                    window.location.href = "/payment/success";
                }else{
                    window.location.href = "/payment/failure";
                }
            } else {
                console.log("ELSE BLOCK")
                const req = JSON.parse(sessionStorage.getItem("req"))
                req.is_save_card = JSON.parse(sessionStorage.getItem("saveCard"))
                const checkStatus = await redirectPageStatus(tapId,req)
                if(checkStatus.data.statusCode==201){
                    setTransId(checkStatus.data.transaction_id)
                    setOrderStatus(true)
                }else if(checkStatus.data.statusCode==400){
                    window.location.href = "/payment/failure";
                }else{
                    console.log("checkStatus", checkStatus)
                    if (checkStatus.data.data.charge.status == "CAPTURED") {
                        console.log("transaction_type",transaction_type)
                        if (transaction_type && transaction_type == "savecard" || transaction_type == "voucher") {
                            window.location.href = "/payment/success";
                        } else if(transaction_type=="order"){
                          setTransId(checkStatus.data.data.transaction_id.transaction_id)

                            console.log("ORDER")
                             setOrderStatus(true)
                        }
                    }else{
                        window.location.href = "/payment/failure";
                    }
                }
               

            }
        }
        requestChargeStatus()

    }, [tapId,transaction_type])

    // useEffect(()=>{
    //     console.log("paymentPayload Add card",transactionPayload)
    
    //   },[transactionPayload.id])
    
    return (
            orderStatus  ? 
            
            <>
             <PaymentSuccess
            payload={payload}
            promoDetail={promoDetail}
            useWallet={useWallet}
            walletValue={walletValue}
            widgetCompleted={widgetCompleted}
            cashback={cashback}
            cartValue={cartValue}
            transId={transId}
            setWidgetCompleted={setWidgetCompleted}
            is_tap={false}
          />
          
           </> : <div>Redirect Success</div>
        
        
    )
}

export default RedirectComp