import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentPayload } from '../redux/paymentSlice';

const TapPayment = ({ shandleSubmit ,payload,setHandleSubmit,setError}) => {
    console.log('payload.transaction_type::: ', payload.transaction_type);
    // setError("ERROR NOTIFICATION")
    // const [error, setError] = useState('');
    const [token, setToken] = useState(null);
   
    const [tap, setTap] = useState(null);
    const [card, setCard] = useState(null);


    const dispatch = useDispatch();
    const paymentPayload = useSelector((state) => state.payment);
    console.log("paymentPayload",paymentPayload)
    const tapPayload = useSelector((state) => state.tap);
    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) return; // Skip effect if already mounted
        hasMounted.current = true; // Set the ref to true
            if (!document.getElementById("card-number")) {
                // Load the Tap SDK script
                const script = document.createElement('script');
                script.src = 'https://secure.gosell.io/js/sdk/tap.min.js';
                script.async = true;
                script.onload = () => {
                    // Initialize Tap instance after the script is loaded
                    const tapInstance = window.Tapjsli('pk_test_NqmvUBctdZGeTunCrYiKRs7V');
                    setTap(tapInstance);
    
                    const elements = tapInstance.elements({});
                    const style = {
                        base: {
                            color: '#535353',
                            lineHeight: '18px',
                            fontFamily: 'sans-serif',
                            fontSmoothing: 'antialiased',
                            fontSize: '16px',
                            '::placeholder': {
                                color: 'rgba(0, 0, 0, 0.26)',
                                fontSize: '15px',
                            },
                        },
                        invalid: {
                            color: 'red',
                        },
                    };
                    const labels = {
                        cardNumber: 'Card Number',
                        expirationDate: 'MM/YY',
                        cvv: 'CVV',
                        cardHolder: 'Card Holder Name',
                    };
                    const paymentOptions = {
                        currencyCode: ['KWD', 'USD', 'SAR'],
                        labels,
                        TextDirection: 'ltr',
                        paymentAllowed: ['VISA', 'MASTERCARD', 'AMERICAN_EXPRESS', 'MADA'],
                    };
    
                    // Create the card element
                    const cardElement = elements.create('card', { style }, paymentOptions);
                    cardElement.mount('#element-container');
    
                    // Listen for card changes
                    cardElement.addEventListener('change', function (event) {
                        if (event.error) {
                            setError(event.error.message);
                        } else {
                            setError('');
                        }
                    });
    
                    setCard(cardElement);
                };
    
                document.body.appendChild(script);
        }

    }, []);


    useEffect(() => {
          if(shandleSubmit){
             const handleSubmit =async () => {
                // console.log("TAP INITIAL CONSOLE",tap,card)
                if (tap && card) {
                    try {
                        // dispatch(setPaymentPayload({...paymentPayload, token: sessionStorage.getItem("token")}));
                        // if (!document.getElementById("card-number")) {
                        //         setError("Please enter the card number")   
                        //         setTimeout(()=>{
                        //             console.log("setTimeout::: ");
                        //             setError("")
                        //             setHandleSubmit(false)
                        //         },2000)
                        //         return
                        // }
                        // if (!document.getElementById("expiration-date")) {
                        //         setError("Please enter the expiration date")   
                        //         setTimeout(()=>{
                        //             console.log("setTimeout::: ");
                        //             setError("")
                        //             setHandleSubmit(false)
                        //         },2000)
                        //         return
                        // }
                        // if (!document.getElementById("card-holder")) {
                        //         setError("Please enter the expiration date")   
                        //         setTimeout(()=>{
                        //             console.log("setTimeout::: ");
                        //             setError("")
                        //             setHandleSubmit(false)
                        //         },2000)
                        //         return
                        // }
                        // if (!document.getElementById("cvv")) {
                        //         setError("Please enter the cvv")   
                        //         setTimeout(()=>{
                        //             console.log("setTimeout::: ");
                        //             setError("")
                        //             setHandleSubmit(false)
                        //         },2000)
                        //         return
                        // }
                       

                        console.log("TRY BLOCK TAP")
                        tap.createToken(card).then(function (result) {
                            console.log("RESULT FROM TAP",result)

                            if (result.error) {
                                console.log("ERROR RESULT FROM TAP",result)
                                setError(result.error.message);
                            } else {
                                console.log("RESULT FROM TAP",result)
                                // let payload = {
                                //     token: result.id,
                                //     last4: result.last_four,
                                //     tap_card_id: result.card.id
                                // }
                                // dispatch(setPaymentPayload({...paymentPayload, token: result.id}));'
                                if(payload.transaction_type !== "savecard"){
                                    dispatch(setPaymentPayload({...paymentPayload, token:  result.id,initialize_savecard:true,instant_pay:true}));
                                }else{
                                    dispatch(setPaymentPayload({...paymentPayload, token:  result.id,initialize_savecard:true,save_card:true}));
                                }
                                console.log("RESULT FROM TAP INSIDE TRY BLOCK",result)
                                // setTapPayload({ ...tapPayload, ...payload })
                                // setToken(result.id);
                                setError('');
                                // console.log('Token:', result.id);
                            }

                        }).catch((e)=>{
                        console.log("ERROR FROM TAP INSIDE CATCH BLOCK",e)
                        })

                        if(payload.transaction_type !== "savecard"){
                            dispatch(setPaymentPayload({...paymentPayload, token:  sessionStorage.getItem("tap_token"),initialize_savecard:true,instant_pay:true}));
                        }else{
                            dispatch(setPaymentPayload({...paymentPayload, token: sessionStorage.getItem("tap_token"),initialize_savecard:true,save_card:true}));
                        }

                    } catch (error) {
                        console.log("ERROR RESULT FROM CATCH BLOCK",error)
                    }
                }
                
                // dispatch(setPaymentPayload({...paymentPayload, token:sessionStorage.getItem('tap_token'), instant_pay:true }));
                // console.log("TAP paymentPayload",paymentPayload)
            }
            handleSubmit()
        }
    }, [shandleSubmit])   

    return (
        <>
        <div>
            <form id="form-container" >
                <div id="element-container"></div>
               
                {/* {token && (
                    <div id="success" style={{ display: 'block' }}>
                        Success! Your token is <span id="token">{token}</span>
                    </div>
                )} */}
                {/* <button id="tap-btn" type="submit">
                    Submit
                </button> */}
            </form>
        </div>

    </>
    );
};

export default TapPayment;
