// PaymentSuccess.js
import React, { useEffect, useState } from "react";
import { formatCurrency } from "./Checkout";
import PaymentCon from "../assets/images/paymentCon.png";
import { redirectPageStatus } from "../services/krti.service";
import { useSelector } from "react-redux";

const PaymentSuccess = ({
  setWidgetCompleted,
  widgetCompleted,
  payload,
  cashback,
  cartValue,
  transcation,
  promoDetail,
  walletValue,
  useWallet,
  transId,
  is_tap = true
}) => {
  const transactionPayload = useSelector((state) => state.transaction)

  console.log("payload::: ", 
    widgetCompleted,
    payload,
    cashback,
    cartValue,
    transcation,
    promoDetail,
    walletValue,
    useWallet,);



  const finalAmount = sessionStorage.getItem("cartTotal");
  const dicountAmount = formatCurrency(cartValue - finalAmount);

  useEffect(() => {
    sessionStorage?.getItem("businessName");
  });

  const backRedirect = () => {
    // console.log("backRedirect:::");
    // console.log("widgetCompleted::: ", widgetCompleted);
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const transactionType = urlParams.get('transaction_type')
    // if ((payload && payload?.transaction_type !== "order" )|| transactionType !== "order" || transactionType !=="voucher"  ) {
    //   window.location.href = "/payment/success";
    //   return null;
    // } else {
    //   setWidgetCompleted(!widgetCompleted);
    // }
    window.location.replace("https://krti-sayshop.theecentral.com/tracking")
  };

  if(is_tap){
    sessionStorage.setItem("widgetCompleted",widgetCompleted)
    sessionStorage.setItem("payload",JSON.stringify(payload))
    sessionStorage.setItem("cashback",cashback)
    sessionStorage.setItem("cartValue",cartValue)
    sessionStorage.setItem("transcation",typeof transcation == "string" ? transcation : (typeof transcation === "object" && transcation !== null) ? JSON.stringify(transcation):null )
    sessionStorage.setItem("promoDetail",promoDetail)
    sessionStorage.setItem("walletValue",walletValue)
    sessionStorage.setItem("useWallet",useWallet)
  } 
  return (
    <>
    {
    is_tap ? <> {console.log("ELSE BLOCK")}</>:
    <>
         <div className="mobilePageWrapper pt_34 ">
        <div className="plr_24">
          <h2 className="fontSize22 fontWeight500 blackClr_text mb_8">
            Payment confirmation  
          </h2>

          <div className="celestialCathedralFrame mb_24">
            <div className="textAlignCenter mb_24">
              <img src={PaymentCon} alt="PaymentCon" />
            </div>
            <h3 className="fontSize22 fontWeight500 blackClr_text textAlignCenter mb_12">
              Thank you for the order
            </h3>

            <h5 className="fontSize14 fontWeight500 blackClr_text mb_8 textAlignCenter">
              Order No:{" "}
              <span>{transId ? transId : transcation ? transcation.payment_master_id : transcation}</span>
            </h5>
            {cashback && (payload && payload?.transaction_type!== "voucher") ? (
              <h4 className="fontSize16 fontWeight500 blackClr_text mb_8 textAlignCenter">
                {" "}
                Cashback earned {formatCurrency(cashback)}
              </h4>
            ) : null}
            <p className="textAlignCenter fontSize14 fontWeight400 blackClr_text">
              As soon as your order begins to be processed, we will send you an
              email. This can take up to 1 hour.
            </p>
          </div>

          <h2 className="fontSize22 fontWeight500 blackClr_text mb_8">
            Order details
          </h2>

          <div className="table_detail">
            <table>
              {
                <tr>
                  <th> Cart Amount:</th>
                  <td> {formatCurrency(cartValue)} </td>
                </tr>
              }

              {promoDetail?.discount_value || promoDetail?.amount ? (
                <tr>
                  <th> Promotional discount:</th>
                  <td>
                    -{" "}
                    {formatCurrency(
                      promoDetail?.discount_value || promoDetail?.amount || 0
                    )}{" "}
                  </td>
                </tr>
              ) : (
                ""
              )}
              {
                <tr>
                  {useWallet && (
                    <th>
                      <label htmlFor="useWallet">Wallet Cash </label>
                    </th>
                  )}
                  <td>
                    {" "}
                    {useWallet &&
                      "- " +
                        formatCurrency(
                          walletValue >
                            +cartValue -
                              (parseFloat(promoDetail?.discount_value) ||
                                parseFloat(promoDetail?.amount) ||
                                0)
                            ? cartValue -
                                (parseFloat(promoDetail?.discount_value) ||
                                  parseFloat(promoDetail?.amount) ||
                                  0)
                            : walletValue
                        )}{" "}
                  </td>
                </tr>
              }
              <tfoot>
                <tr>
                  <th>
                    <label htmlFor="useWallet">Total discount</label>
                  </th>
                  <td>- {dicountAmount} </td>
                </tr>
                <tr>
                  <th className="fontweight500">Total amount</th>
                  <td>
                    <span className="fontweight500">
                      {formatCurrency(finalAmount)}
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="pageFooter">
          <div>
            <button onClick={backRedirect} className="blackMainbtn width100per height50px">
              {" "}
              Back to Cart
            </button>
          </div>
        </div>
      </div>
      </>
    }
   
    </>
  );
};

export default PaymentSuccess;
