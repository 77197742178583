import { createSlice } from "@reduxjs/toolkit";

const tapSlice = createSlice({
    name:"transaction",
    initialState:{
        id:null,
        type:false,  
    },
    reducers:{
        setTransactionPayload:(state,action)=>{
          const {id,type} = action.payload;
          state.id = id;
          state.type = type;
        },
        resetTransactionPayload: (state) => {
            state.id = null;
            state.type = null;
          },
    }
})


export const { setTransactionPayload, resetTapPayload } = tapSlice.actions;
export default tapSlice.reducer;