import React, { useEffect, useState } from "react";
import { authMerchant, PayUsingTap, saveCard } from "../services/krti.service";
import Leftbackarrow from "../assets/images/leftbackarrow.svg";
import toast from "react-hot-toast";
import CheckoutFrame from "./CheckoutFrame";
// import { Frames } from "frames-react";
import TapPayment from "./TapPayment";
import { useDispatch, useSelector } from "react-redux";
import { resetPaymentPayload, setPaymentPayload } from "../redux/paymentSlice";

const SaveCards = ({ payload,setAddCards }) => {
  const merchant_secret = sessionStorage.getItem('merchant_secret');
  const client_id = sessionStorage.getItem('client_id');
  const [submitError, setSubmitError] = useState("");
  const [checkedIndex, setCheckedIndex] = useState(false);
  const [saveCardBool, setSaveCardBool] = useState(false);
    const [tap, setTap] = useState(payload);
    const [card, setCard] = useState(null);
  // const [pay, setPay] = useState("not_verified");
  console.log("payload",payload)


  const [handleSubmit,setHandleSubmit] = useState(false) 
  const paymentPayload = useSelector((state)=> state.payment)
  // const tapc = useSelector((state)=> state.tap)
  const dispatch = useDispatch();

  const [tapPayload, setTapPayload] = useState({
    user_id: payload?.user_data?.id,
    is_save_card: false,
    mobile_code: payload?.mobile_code,
    amount: +payload?.amount,
    mobile: payload?.mobile,
  });


  // const SaveCard = async (checkoutRes) => {
  //   try {
  //     console.log("checkoutRes::: ", checkoutRes);
  //     if (Object.keys(checkoutRes).length > 0) {
  //       console.log("payload?.user_data?.id::: ", payload?.user_data?.id);
  //       setSubmitError(null);
  //       const req = {
  //         user_id: payload?.user_data?.id,
  //         token: checkoutRes.token,
  //         expiry_year: checkoutRes.expiry_year,
  //         last4: checkoutRes.last4,
  //         is_save_card: true,
  //       };
  //       console.log("req::: ", req);
  //       const res = await saveCard(req);
  //       if (res?.data?.statusCode === 200) {
  //         const data = res?.data?.data;
  //         if (data?.isSuccess === false) {
  //           console.log("data?.isSuccess === false)::: ");
  //           setTimeout(() => {
  //             console.log("setTimeout::: ");
  //             // Frames.init();
  //             setSubmitError(null);
  //           }, 2000);
  //           return setSubmitError(
  //             res?.data?.data?.message || res?.message || res?.data?.message
  //           );
  //         }
  //         window.location.href = "/payment/success";
  //       } else {
  //         setSubmitError(res?.message || res?.data?.message);
  //         setTimeout(() => {
  //           console.log("setTimeout::: ");
  //           // Frames.init();
  //           setSubmitError(null);
  //         }, 2000);
  //       }
  //     }
  //   } catch (error) {
  //     if (error?.message) {
  //       const msg = error?.message?.split(":")?.[1]?.trim();
  //       // alert(msg);
  //       console.error(msg);
  //       setSubmitError(msg || error?.message);
  //       setTimeout(() => {
  //         // Frames.init();
  //         setSubmitError(null);
  //       }, 2000);
  //     }
  //     console.log(error.message);
  //   }
  // };

  // console.log('tapPayload::: ', tapPayload);

  const saveCardFunc =async ()=>{
    // SaveTapCard()
  }

  useEffect(() => {
    // console.log("INITIZIALEEE SAVE CARD",paymentPayload)
    if(paymentPayload.initialize_savecard){
      // console.log("SAVE CARD ",paymentPayload.save_card,paymentPayload.token)
      // setTapPayload({...tapPayload,token:paymentPayload.token})
      if(paymentPayload.save_card){
          SaveTapCard()
      }
      else{
        dispatch(setPaymentPayload({...paymentPayload,initialize_payment:true}))
      }

    }
      // SaveTapCard()
  }, [paymentPayload.token])

  const SaveTapCard = async () => {
    try {

    //   if (tap && card) {
    //     try {
    //         console.log("TRY BLOCK TAP")
    //         tap.createToken(card).then(function (result) {
    //             console.log("RESULT FROM TAP",result)

    //             if (result.error) {
    //                 console.log("ERROR RESULT FROM TAP",result)
    //                 // setError(result.error.message);
    //             } else {
    //                 console.log("RESULT FROM TAP",result)
    //                 // let payload = {
    //                 //     token: result.id,
    //                 //     last4: result.last_four,
    //                 //     tap_card_id: result.card.id
    //                 // }
    //                 dispatch(setPaymentPayload({...paymentPayload, token: result.id}));
    //                 console.log("RESULT FROM TAP INSIDE TRY BLOCK",result)
    //                 // setTapPayload({ ...tapPayload, ...payload })
    //                 // setToken(result.id);
    //                 // setError('');
    //                 // console.log('Token:', result.id);
    //             }
    //         }).catch((e)=>{
    //             console.log("ERROR FROM TAP INSIDE CATCH BLOCK",e)
    //         })
    //     } catch (error) {
    //         console.log("ERROR RESULT FROM CATCH BLOCK",error)
    //     }
    // }

      // console.log("SAVE CARD INITIALIZED",paymentPayload)
      // console.log("payload?.user_data?.id::: ", payload?.user_data?.id);
      setSubmitError(null);
      // debugger;
      const req = tapPayload;
      req.token = paymentPayload.token
      // req.is_save_card = paymentPayload.save_card
      req.tap_customer_id = paymentPayload.tap_customer_id
      // console.log("payload.transaction_type",payload.transaction_type)
      if(payload.transaction_type=="savecard"){
        req.is_save_card = true
      }
      // console.log("SAVE CARD API REQUEST::: ", req);
      const res = await saveCard(req);
      if (res?.data?.statusCode === 200) {
        if (payload.transaction_type !== "savecard") {
          dispatch(setPaymentPayload({...paymentPayload,initialize_payment:true}))
        }
        else{
          window.location.href="payment/success"
        }
        // setTapToken
        // setPayNow(true)
        // setTapData(tapPayload)
        // console.log(tapPayload, "TAP_PAYLOAD")
        // const data = res?.data?.data;
        // if (data?.isSuccess === false) {
        //   console.log("data?.isSuccess === false)::: ");
        //   setTimeout(() => {
        //     console.log("setTimeout::: ");
        //     // Frames.init();
        //     setSubmitError(null);
        //   }, 2000);
        //   return setSubmitError(
        //     res?.data?.data?.message || res?.message || res?.data?.message
        //   );
        // }
        // window.location.href = "/payment/success";
      } else if(res?.data?.statusCode === 201){
        console.log(res?.data)
        const url = res?.data?.url
        window.location.replace(url)
      }
      
      else {
        console.log(res?.message,"res?.message")
        setSubmitError(res?.data?.message);
        setTimeout(() => {
          console.log("setTimeout::: ");
          // Frames.init();
            setSubmitError(null);
        }, 2000);
        setHandleSubmit(false)
        // dispatch(setPaymentPayload({...paymentPayload,instant_pay:false,initialize_savecard:false,initialize_payment:false }))
      }

    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
        setSubmitError(msg || error?.message);
        setTimeout(() => {
          // Frames.init();
          setSubmitError(null);
        }, 2000);
      }
      console.log(error.message);
    }
  };
  // const setTapPay = () => {
  //   setPay("sent_to_tap")
  // }

  const handleSaveCard = (event) => {
    const checked = event.target.checked;
    console.log('checked::: ', checked);
    sessionStorage.setItem("saveCard", checked);
    setCheckedIndex(checked);
    dispatch(setPaymentPayload({...paymentPayload,save_card:checked}));
  };
  const authKrtiMerchant = async () => {
    try {
      const req = {
        amount: payload?.amount,
        mobile: payload?.mobile,
        mobile_code: payload?.mobile_code,
        transaction_type: payload?.transaction_type,
        voucher_id: payload?.voucher_id,
        merchant_secret,
        client_id,
      };

      const res = await authMerchant(payload?.brand_id, req);
      if (res?.data?.statusCode === 200) {
        sessionStorage.setItem("K-token", res?.data?.data?.token);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (payload.transaction_type !== "savecard") {
      document.body.classList.add("loading-indicator-savecard");
      setTimeout(() => {
        document.body.classList.remove("loading-indicator-savecard");
      }, 2000);
    }
    if (payload.transaction_type === "savecard") {
      authKrtiMerchant();
    }
  }, []);

  return (
    <>
      {payload.transaction_type !== "savecard" ? (
        <>
          <div className="plr_24">
            {payload.transaction_type !== "savecard" ? (
              <div className="mb_34">
                <button
                  className="backCirclebtn platonicBlueCircle"
                  onClick={() => {setAddCards(false);
                    dispatch(resetPaymentPayload({...paymentPayload, save_card:false,initialize_savecard:false,initialize_payment:false}))

                  }}
                >
                  <img src={Leftbackarrow} alt="Leftbackarrow" />
                </button>
              </div>
            ) : null}
            {/* <div>
          <h2 className="fontSize22 fontWeight500 blackClr_text mb_8">
            Add card details
          </h2>
        </div> */}

            {/* CHECKOUT FRAME */}
            {/* <div className="celestialCathedralFrame">
              <CheckoutFrame
                SaveCard={SaveCard}
                payload={payload}
                paycart={paycart}
              />

              {payload.transaction_type !== "savecard" ? (
                <div className="inputCheckbox">
                  <input
                    id="useWallet"
                    type="checkbox"

                    onChange={(e) => handleSaveCard(e)}
                  />
                  <label htmlFor="useWallet">Secure this card</label>
                </div>
              ) : null}
            </div> */}
            <div className="celestialCathedralFrame">
              <TapPayment shandleSubmit={handleSubmit} setHandleSubmit={setHandleSubmit} setError={setSubmitError} payload={payload}/>

              {payload.transaction_type !== "savecard" ? (
                <div className="inputCheckbox">
                  <input
                    id="useWallet"
                    type="checkbox"
                    onChange={(e) => handleSaveCard(e)}
                  />
                  <label htmlFor="useWallet">Secure this card</label>
                </div>
              ) : null}
            </div>

            <div className="displayFlex gap5px center">
              {submitError && <div className="errorMsg">{submitError}</div>}
            </div>
          </div>
          <div className="pageFooter">
            <div>
              <button
                id="pay-button"
                // onClick={(e) => {
                //   Frames.submitCard();
                // }}
                onClick={(e) => {
                  // setSaveCardBool(true)
                  payload.transaction_type !== "savecard" && setHandleSubmit(true)
                  payload.transaction_type !== "savecard" ?  dispatch(setPaymentPayload({...paymentPayload,initialize_savecard:true})):
                  setHandleSubmit(true);
                  saveCardFunc();
                }}
                // onclick="tokenize()"
                className="blackMainbtn width100per height50px"
              >
                {payload.transaction_type !== "savecard"
                  ? checkedIndex
                    ? "SAVE & PROCEED"
                    : "PROCEED"
                  : null}
                {payload.transaction_type === "savecard" ? "SAVE CARD" : null}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="mobilePageWrapper pt_60">
          <div className="plr_24">
            {payload.transaction_type !== "savecard" ? (
              <div className="mb_34">
                <button
                  className="backCirclebtn platonicBlueCircle"
                  onClick={() => {
                    setAddCards(false);
                    dispatch(resetPaymentPayload({...paymentPayload, save_card:false,initialize_savecard:false,initialize_payment:false}))
                  }
                  }
                >
                  <img src={Leftbackarrow} alt="Leftbackarrow"  />
                  
                </button>
              </div>
            ) : null}
            {/* <div>
          <h2 className="fontSize22 fontWeight500 blackClr_text mb_8">
            Add card details
          </h2>
        </div> */}
         <div className="celestialCathedralFrame">
              <TapPayment shandleSubmit={handleSubmit} setHandleSubmit={setHandleSubmit} setError={setSubmitError} payload={payload}/>

              {payload.transaction_type !== "savecard" ? (
                <div className="inputCheckbox">
                  <input
                    id="useWallet"
                    type="checkbox"
                    onChange={(e) => handleSaveCard(e)}
                  />
                  <label htmlFor="useWallet">Secure this card</label>
                </div>
              ) : null}
            </div>
            {/* CHECKOUT FRAME */}
              {/* <div className="celestialCathedralFrame">
              <CheckoutFrame
                SaveCard={SaveCard}
                payload={payload}
                paycart={paycart}
              />

              {payload.transaction_type !== "savecard" ? (
                <div className="inputCheckbox">
                  <input
                    id="useWallet"
                    type="checkbox"
                    onChange={(e) => handleSaveCard(e)}
                  />
                  <label htmlFor="useWallet">Secure this card</label>
                </div>
              ) : null}
            </div> */}
            <div className="displayFlex gap5px center">
              {submitError && <div className="errorMsg">{submitError}</div>}
            </div>
          </div>
          <div className="pageFooter">
            <div>
              <button
                id="pay-button"
                onClick={(e) => {
                  payload.transaction_type !== "savecard" && setHandleSubmit(true)
                  payload.transaction_type !== "savecard" ?  dispatch(setPaymentPayload({...paymentPayload,initialize_savecard:true})):
                  setHandleSubmit(true);
                  saveCardFunc();
                }}
                className="blackMainbtn width100per height50px"
              >
                {payload.transaction_type !== "savecard"
                  ? checkedIndex
                      ? "SAVE & PROCEED"
                    : "PROCEED"
                  : null}
                {payload.transaction_type === "savecard" ? "SAVE CARD" : null}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SaveCards;
