import React, { useState, useEffect } from "react";
import LinkAccount from "./components/LinkAcccounts";
import OtpVerification from "./components/OtpVerification";
import AddCard from "./components/AddCard";
import Checkout from "./components/Checkout";
import "./App.css";
import PaymentSuccess from "./components/PaymentSuccess";
import { useLocation } from "react-router-dom";
import { authMerchant } from "./services/krti.service";
import UnauthorizedDisplay from "./components/UnauthorizedDisplay";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import ErrorBoundary from "./components/ErrorBoundry";
import SaveCards from "./components/SaveCards";
import { useSelector } from "react-redux";

function App() {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const [isTap, setIsTap] = useState(true);
  const [walletValue, setWalletValue] = useState(0); // initial wallet value in KWD
  const [cartValue, setCartValue] = useState(0); // initial cart value in KWD
  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [widgetCompleted, setWidgetCompleted] = useState(false); // State to track if widget processes are completed
  const [promoDetail, setPromodetail] = useState(null);
  const [useWallet, setUseWallet] = useState(false);
  const [cashback, setCashback] = useState(0);
  const [transcation, setTranscation] = useState(0);
  let payload;
  const transactionPayload = useSelector((state) => state.transaction)
  console.log("transactionPayload",transactionPayload)

  let merchant_secret;
  let client_id;

  try {
    const dataParam = queryParams.get("data");
    sessionStorage.setItem("redirect_url",window.location.href)
    merchant_secret = queryParams.get("merchant_secret") || null;
    client_id = queryParams.get("client_id") || null;
    sessionStorage.setItem(
      "merchant_secret",
      queryParams.get("merchant_secret") || null
    );
    sessionStorage.setItem("client_id", queryParams.get("client_id") || null);
    if (dataParam) {
      payload = JSON?.parse(atob(dataParam));
    } else {
      throw new Error("No 'data' parameter found in the query string.");
    }
  } catch (error) {
    console.error("Failed to parse payload:", error);
    payload = null; // or set a default value
  }

  console.log("payload",payload)

  const handleNextStep = (add = 1) => {
    setStep(step + add);
  };
  const handlePrevStep = () => setStep(step - 1);

  useEffect(() => {
    const authKrtiMerchant = async () => {
      try {
        if (payload?.is_register) return null;
        const req = {
          amount: payload?.amount,
          mobile: payload?.mobile,
          mobile_code: payload?.mobile_code,
          transaction_type: payload?.transaction_type,
          voucher_id: payload?.voucher_id,
          merchant_secret,
          client_id,
        };

        const res = await authMerchant(payload?.brand_id, req);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          sessionStorage.setItem("K-token", res?.data?.data?.token);
          Cookies.set("K-token", res?.data?.data?.token);
          // setIsAuthorized(true)
        } else {
          toast.error(res?.message || res?.data?.message);
          // setIsAuthorized(false)
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    sessionStorage.setItem("cartValue",payload?.amount || 0)
    setCartValue(payload?.amount || 0);
    if (!payload) {
      setIsAuthorized(false);
    }
    if (payload) {
      authKrtiMerchant();
      if (payload.transaction_type === "savecard") {
        setStep(5);
      } else {
        setStep(payload?.is_register ? 3 : 1);
      }
    }
  }, []);

  // const transaction_type = sessionStorage.getItem("transaction_type")
  const transaction_id = sessionStorage.getItem("transaction_id")

  useEffect(()=>{
    console.log("transaction_type",promoDetail,"transaction_id",transaction_id)
    if(transactionPayload.id){
      if(transactionPayload.type =="savecard"){
        window.location.href = "/payment/success";
      }else{
        setStep(6)
        setTranscation(transaction_id)
      }
    }

  },[promoDetail])

  // Function to send a message to the parent window to close the widget
  const sendCloseWidgetMessage = () => {
    window.parent.postMessage(step === 6 ? "closeWidget" : "back", "*");
  };

  useEffect(() => {
    // Check if all widget processes are completed, then send a message to the parent to close the widget
    if (widgetCompleted) {
      sendCloseWidgetMessage();
    }
  }, [widgetCompleted]);

  return (
    <>
      <ErrorBoundary>
        {isAuthorized && (
          <>
            {step === 1 && (
              <LinkAccount
                phone={phone}
                setPhone={setPhone}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
                payload={payload}
                setWidgetCompleted={setWidgetCompleted}
                widgetCompleted={widgetCompleted}
              />
            )}
            {step === 2 && (
              <OtpVerification
                otp={otp}
                setOtp={setOtp}
                handleNextStep={handleNextStep}
                setIsNewUser={setIsNewUser}
                handlePrevStep={handlePrevStep}
                phone={phone}
              />
            )}
            {step === 3 && (
              <Checkout
                walletValue={walletValue}
                cartValue={cartValue}
                setWalletValue={setWalletValue}
                handleNextStep={handleNextStep}
                setTranscation={setTranscation}
                handlePrevStep={setStep}
                payload={payload}
                promoDetail={promoDetail}
                setPromodetail={setPromodetail}
                setWidgetCompleted={setWidgetCompleted}
                widgetCompleted={widgetCompleted}
                useWallet={useWallet}
                setUseWallet={setUseWallet}
                cashback={cashback}
                setCashback={setCashback}
                setIsTap={setIsTap}
              />
            )}
            {step === 4 && (
              <AddCard
                walletValue={walletValue}
                // handleNextStep={handleNextStep}
                cartValue={cartValue}
                handlePrevStep={handlePrevStep}
                setStep={setStep}
                payload={payload}
                promoDetail={promoDetail}
                useWallet={useWallet}
                setTranscation={setTranscation}
              />
            )}

            {/* {step === 5 && (
                <Payment cartValue={cartValue} handlePrevStep={handlePrevStep} />
            )} */}
            {step === 5 && (
              <SaveCards payload={payload} setStep={setStep} widgetCompleted={widgetCompleted} />
            )}

            {step === 6 && (
              <PaymentSuccess
                handlePrevStep={handlePrevStep}
                setWidgetCompleted={setWidgetCompleted}
                payload={payload}
                promoDetail={promoDetail}
                useWallet={useWallet}
                walletValue={walletValue}
                widgetCompleted={widgetCompleted}
                cashback={cashback}
                cartValue={cartValue}
                transcation={transcation}
                is_tap={isTap}
              />
            )}
          </>
        )}

        {!isAuthorized && <UnauthorizedDisplay />}
      </ErrorBoundary>
    </>
  );
}

export default App;
